import React from 'react';
import { Box, Container, Grid, Typography, Link, useTheme, Stack } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { motion } from 'framer-motion';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box 
      component="footer" 
      sx={{ 
        bgcolor: 'white',
        py: { xs: 4, md: 6 },
        borderTop: `1px solid ${theme.palette.divider}`,
        mt: 'auto',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} sx={{ mb: { xs: 2, md: 4 } }}>
          <Grid item xs={12} md={4}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 600,
                  mb: 2,
                  fontFamily: '"Montserrat", sans-serif',
                  color: theme.palette.primary.main
                }}
              >
                The GreenVantage
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  maxWidth: 300,
                  lineHeight: 1.8
                }}
              >
                Transforming digital advertising through sustainable innovation and performance optimization.
              </Typography>
            </motion.div>
          </Grid>

          <Grid item xs={12} md={4}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.1 }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>Contact</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <LocationOnIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="body2" color="text.secondary">
                  Office 1502, 15th Floor, Swiss Tower<br />
                  Cluster Y, Jumeirah Lake Towers<br />
                  PO Box 358639, Dubai, UAE
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <PhoneIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Link 
                  href="tel:044253300" 
                  underline="none" 
                  color="text.secondary"
                  sx={{ 
                    '&:hover': { color: 'primary.main' }
                  }}
                >
                  04 425 3300
                </Link>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Link 
                  href="mailto:info@thevantage.com" 
                  underline="none" 
                  color="text.secondary"
                  sx={{ 
                    '&:hover': { color: 'primary.main' }
                  }}
                >
                  info@thevantage.com
                </Link>
              </Box>
            </motion.div>
          </Grid>

          <Grid item xs={12} md={4}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>Quick Links</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Link 
                  href="/solutions" 
                  underline="none" 
                  color="text.secondary"
                  sx={{ 
                    '&:hover': { color: 'primary.main' }
                  }}
                >
                  Solutions
                </Link>
                <Link 
                  href="/about" 
                  underline="none" 
                  color="text.secondary"
                  sx={{ 
                    '&:hover': { color: 'primary.main' }
                  }}
                >
                  About Us
                </Link>
                <Link 
                  href="/contact" 
                  underline="none" 
                  color="text.secondary"
                  sx={{ 
                    '&:hover': { color: 'primary.main' }
                  }}
                >
                  Contact
                </Link>
              </Box>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Link
                  href="https://www.linkedin.com/company/the-vantage-me/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      color: '#0A66C2'
                    }
                  }}
                >
                  <LinkedInIcon />
                </Link>
              </Stack>
            </motion.div>
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: 6,
            pt: 4,
            borderTop: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: { xs: 3, sm: 2 }
          }}
        >
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{
              textAlign: { xs: 'center', sm: 'left' }
            }}
          >
            {new Date().getFullYear()} The GreenVantage. All rights reserved.
          </Typography>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <Box 
              sx={{ 
                '& #tree-nation-offset-website': {
                  transform: { xs: 'scale(0.85)', sm: 'none' },
                  transformOrigin: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  '& iframe': {
                    maxWidth: '100%',
                    width: 'auto',
                    height: 'auto',
                    minHeight: '50px'
                  }
                }
              }}
            >
              <div id="tree-nation-offset-website"></div>
            </Box>
          </motion.div>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;