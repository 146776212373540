// Function to generate a unique certificate ID
export const generateCertificateId = (name) => {
  const cleanName = name.toLowerCase()
    .replace(/[^a-z0-9]/g, '') // Remove special characters
    .slice(0, 20); // Take first 20 characters
  const timestamp = Date.now().toString(36); // Convert timestamp to base36
  const randomStr = Math.random().toString(36).substr(2, 5);
  return `${cleanName}-${timestamp}-${randomStr}`;
};

// Function to encode certificate data
export const encodeCertificateData = (name) => {
  const id = generateCertificateId(name);
  const data = {
    name,
    id,
    timestamp: Date.now()
  };
  return btoa(JSON.stringify(data))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

// Function to decode certificate data
export const decodeCertificateData = (encoded) => {
  try {
    // Add back padding if needed
    const base64 = encoded
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const padding = base64.length % 4;
    const paddedBase64 = padding ? base64 + '='.repeat(4 - padding) : base64;
    
    const decoded = atob(paddedBase64);
    return JSON.parse(decoded);
  } catch (error) {
    console.error('Error decoding certificate:', error);
    return null;
  }
};
