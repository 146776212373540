import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, TextField, Button, Paper, useTheme, IconButton } from '@mui/material';
import CountUp from 'react-countup';
import {
  Stepper,
  Step,
  StepLabel,
  Snackbar,
  Alert,
  Stack,
  CircularProgress,
} from '@mui/material';
import { motion } from 'framer-motion';
import ForestIcon from '@mui/icons-material/Forest';
import ParkIcon from '@mui/icons-material/Park';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import ShareIcon from '@mui/icons-material/Share';
import tgvBrandmark from '../assets/TGVbrandmark.png';
import treeNationLogo from '../assets/treenation_logo_gradient.56ee419.png';
import { sendTreePlantingEmail } from '../services/emailService';
import { supabase } from '../lib/supabaseClient';

const TreePlanting = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    company: ''
  });
  const [treeCount, setTreeCount] = useState(null);
  const [prevTreeCount, setPrevTreeCount] = useState(null);
  const [showPlusOne, setShowPlusOne] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setFormData(prev => ({ ...prev, email }));
  };

  // Load initial tree count and setup real-time subscription
  useEffect(() => {
    const fetchTreeCount = async () => {
      try {
        const { count, error } = await supabase
          .from('tree_plantings')
          .select('*', { count: 'exact', head: true });
        
        if (error) {
          console.error('Error fetching tree count:', error);
          return;
        }

        setTreeCount(count || 0);
        setPrevTreeCount(count || 0);
      } catch (error) {
        console.error('Error fetching tree count:', error);
      }
    };

    fetchTreeCount();

    // Subscribe to changes
    const subscription = supabase
      .channel('tree_plantings')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'tree_plantings' }, 
        (payload) => {
          setTreeCount((currentCount) => {
            const newCount = currentCount + 1;
            // Only show +1 animation if it's not the initial load
            if (currentCount !== null) {
              setShowPlusOne(true);
              setTimeout(() => setShowPlusOne(false), 1000);
            }
            setPrevTreeCount(currentCount);
            return newCount;
          });
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name.trim()) return;
    
    setIsSubmitting(true);
    try {
      await sendTreePlantingEmail(formData);
      setFormData({ name: '', email: '', message: '', company: '' });
      setActiveStep(2); // Move to celebration step
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const steps = ['Welcome', 'Plant Your Tree', 'Celebration'];

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Typography variant="h3" sx={{ 
              mb: { xs: 1.5, sm: 2 }, 
              fontWeight: 700,
              fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2.5rem' },
              textAlign: 'center',
              lineHeight: 1.3
            }}>
              Hey! 👋 Ready to Plant Your Tree?
            </Typography>
            <Typography variant="h5" sx={{ 
              mb: { xs: 2, sm: 3, md: 4 }, 
              color: 'text.secondary',
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.5rem' },
              textAlign: 'center',
              lineHeight: 1.4
            }}>
              Join us in creating a greener future
            </Typography>
            <Typography variant="body1" sx={{ 
              mb: { xs: 3, sm: 4 }, 
              color: 'text.secondary',
              textAlign: 'center',
              px: { xs: 1, sm: 2, md: 4 },
              fontSize: { xs: '0.875rem', sm: '1rem' },
              lineHeight: 1.6
            }}>
              Each tree we plant together makes a real difference to our environment. Let's create positive change, one tree at a time.
            </Typography>
            <Box sx={{ 
              mb: { xs: 3, sm: 4 },
              textAlign: 'center'
            }}>
              <Typography variant="h2" sx={{ 
                fontWeight: 700, 
                color: theme.palette.success.main,
                fontSize: { xs: '2.5rem', sm: '3.5rem' }
              }}>
                {treeCount === null ? (
                  <CircularProgress size={40} color="success" />
                ) : (
                  <CountUp
                    start={prevTreeCount}
                    end={treeCount}
                    duration={1}
                    useEasing={true}
                  />
                )}
              </Typography>
              <Typography variant="h6" sx={{ 
                color: 'text.secondary',
                fontSize: { xs: '1rem', sm: '1.25rem' }
              }}>
                Trees Planted So Far 🌳
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setActiveStep(1)}
                startIcon={<ForestIcon />}
                sx={{
                  py: 1.5,
                  px: 4,
                  bgcolor: theme.palette.success.main,
                  '&:hover': {
                    bgcolor: theme.palette.success.dark,
                  },
                }}
              >
                Start Planting
              </Button>
            </Box>
          </motion.div>
        );

      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Typography variant="h4" sx={{ 
              mb: { xs: 3, sm: 4 }, 
              textAlign: 'center',
              fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' }
            }}>
              Plant Your Tree
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Your Name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                sx={{ 
                  mb: { xs: 2, sm: 3 },
                  '& .MuiInputLabel-root': {
                    fontSize: { xs: '0.875rem', sm: '1rem' }
                  },
                  '& .MuiInputBase-input': {
                    fontSize: { xs: '0.875rem', sm: '1rem' }
                  }
                }}
                required
              />
              <TextField
                fullWidth
                label="Email Address"
                type="email"
                value={formData.email}
                onChange={handleEmailChange}
                required
                sx={{ 
                  mb: { xs: 2, sm: 3 },
                  '& .MuiInputLabel-root': {
                    fontSize: { xs: '0.875rem', sm: '1rem' }
                  },
                  '& .MuiInputBase-input': {
                    fontSize: { xs: '0.875rem', sm: '1rem' }
                  },
                  '& .MuiFormHelperText-root': {
                    fontSize: { xs: '0.75rem', sm: '0.875rem' }
                  }
                }}
                helperText="We'll send you updates about your tree's growth"
              />
              <TextField
                fullWidth
                label="Company"
                value={formData.company}
                onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                sx={{ 
                  mb: { xs: 2, sm: 3 },
                  '& .MuiInputLabel-root': {
                    fontSize: { xs: '0.875rem', sm: '1rem' }
                  },
                  '& .MuiInputBase-input': {
                    fontSize: { xs: '0.875rem', sm: '1rem' }
                  }
                }}
              />
              <TextField
                fullWidth
                label="Want to share why you're planting? (Optional)"
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                multiline
                rows={2}
                sx={{ 
                  mb: { xs: 3, sm: 4 },
                  '& .MuiInputLabel-root': {
                    fontSize: { xs: '0.875rem', sm: '1rem' }
                  },
                  '& .MuiInputBase-input': {
                    fontSize: { xs: '0.875rem', sm: '1rem' }
                  }
                }}
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting || !formData.name.trim() || !formData.email.trim()}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : <ForestIcon />}
                sx={{
                  py: 1.5,
                  bgcolor: theme.palette.success.main,
                  '&:hover': {
                    bgcolor: theme.palette.success.dark,
                  },
                }}
              >
                {isSubmitting ? 'Planting...' : 'Plant Your Tree'}
              </Button>
            </form>
          </motion.div>
        );

      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}>
              {/* Success Icon */}
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ 
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                  delay: 0.2 
                }}
              >
                <Box
                  sx={{
                    width: 120,
                    height: 120,
                    borderRadius: '50%',
                    bgcolor: 'rgba(46, 125, 50, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 3
                  }}
                >
                  <ParkIcon sx={{ 
                    fontSize: 60, 
                    color: theme.palette.success.main 
                  }} />
                </Box>
              </motion.div>

              {/* Thank You Message */}
              <Typography 
                variant="h3" 
                sx={{ 
                  mb: { xs: 1.5, sm: 2 },
                  fontWeight: 700,
                  fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }
                }}
              >
                Thank You! 🌱
              </Typography>
              
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: { xs: 3, sm: 4 }, 
                  color: 'text.secondary',
                  maxWidth: '600px',
                  fontSize: { xs: '0.875rem', sm: '1rem', md: '1.2rem' },
                  lineHeight: 1.6,
                  px: { xs: 1, sm: 2 }
                }}
              >
                Your tree will be planted soon. We've sent you an email confirmation with all the details.
              </Typography>

              {/* Tree Counter Card */}
              <Paper
                elevation={0}
                sx={{ 
                  p: { xs: 2, sm: 3, md: 4 },
                  bgcolor: 'rgba(46, 125, 50, 0.08)',
                  borderRadius: { xs: 3, sm: 4 },
                  width: '100%',
                  maxWidth: '400px',
                  mb: { xs: 3, sm: 4 },
                  border: '2px solid rgba(46, 125, 50, 0.12)',
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                >
                  {/* Plus One Animation */}
                  {showPlusOne && (
                    <motion.div
                      initial={{ opacity: 1, y: 0 }}
                      animate={{ opacity: 0, y: -30 }}
                      transition={{ duration: 1, ease: "easeOut" }}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: theme.palette.success.main,
                        fontWeight: 'bold',
                        fontSize: '2rem',
                        pointerEvents: 'none'
                      }}
                    >
                      +1
                    </motion.div>
                  )}
                  
                  <Typography 
                    variant="h2" 
                    sx={{ 
                      fontWeight: 800,
                      color: theme.palette.success.main,
                      fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
                      mb: { xs: 0.5, sm: 1 },
                      lineHeight: 1.2
                    }}
                  >
                    {treeCount === null ? (
                      <CircularProgress size={40} color="success" />
                    ) : (
                      <CountUp
                        start={prevTreeCount}
                        end={treeCount}
                        duration={1}
                        useEasing={true}
                      />
                    )}
                  </Typography>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      color: theme.palette.success.dark,
                      fontSize: { xs: '0.875rem', sm: '1.1rem', md: '1.25rem' },
                      fontWeight: 500
                    }}
                  >
                    Trees Planted So Far 🌳
                  </Typography>
                </motion.div>
              </Paper>

              {/* Share Section */}
              <Box sx={{ 
                width: '100%',
                maxWidth: '400px',
                textAlign: 'center'
              }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    mb: 2,
                    color: theme.palette.text.secondary,
                    fontSize: '1.1rem'
                  }}
                >
                  Share Your Impact
                </Typography>

                <Stack 
                  direction="row" 
                  spacing={2} 
                  justifyContent="center" 
                  sx={{ mb: 3 }}
                >
                  {/* WhatsApp */}
                  <IconButton
                    onClick={() => {
                      const shareText = `I just planted a tree with GreenVantage! Together, we've planted ${treeCount} trees! Join us in making a difference! 🌳`;
                      window.open(`https://wa.me/?text=${encodeURIComponent(shareText)}`, '_blank');
                    }}
                    sx={{
                      bgcolor: '#25D366',
                      color: '#fff',
                      '&:hover': {
                        bgcolor: '#128C7E',
                        transform: 'translateY(-2px)'
                      },
                      transition: 'all 0.3s ease'
                    }}
                  >
                    <WhatsAppIcon />
                  </IconButton>

                  {/* Twitter/X */}
                  <IconButton
                    onClick={() => {
                      const shareText = `I just planted a tree with GreenVantage! Together, we've planted ${treeCount} trees! Join us in making a difference! 🌳`;
                      window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`, '_blank');
                    }}
                    sx={{
                      bgcolor: '#000000',
                      color: '#fff',
                      '&:hover': {
                        bgcolor: '#333333',
                        transform: 'translateY(-2px)'
                      },
                      transition: 'all 0.3s ease'
                    }}
                  >
                    <TwitterIcon />
                  </IconButton>

                  {/* LinkedIn */}
                  <IconButton
                    onClick={() => {
                      const linkedInText = `I just planted a tree with GreenVantage! Together, we've planted ${treeCount} trees! Join us in making a difference! 🌳\n\nConnect with GreenVantage: https://www.linkedin.com/company/the-vantage-me/`;
                      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&summary=${encodeURIComponent(linkedInText)}`, '_blank');
                    }}
                    sx={{
                      bgcolor: '#0A66C2',
                      color: '#fff',
                      '&:hover': {
                        bgcolor: '#004182',
                        transform: 'translateY(-2px)'
                      },
                      transition: 'all 0.3s ease'
                    }}
                  >
                    <LinkedInIcon />
                  </IconButton>

                  {/* Email */}
                  <IconButton
                    onClick={() => {
                      const subject = "I Just Planted a Tree! 🌱";
                      const emailBody = `I just planted a tree with GreenVantage! Together, we've planted ${treeCount} trees!\n\nConnect with GreenVantage:\nLinkedIn: https://www.linkedin.com/company/the-vantage-me/`;
                      window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
                    }}
                    sx={{
                      bgcolor: '#EA4335',
                      color: '#fff',
                      '&:hover': {
                        bgcolor: '#B31412',
                        transform: 'translateY(-2px)'
                      },
                      transition: 'all 0.3s ease'
                    }}
                  >
                    <EmailIcon />
                  </IconButton>

                  {/* Native Share (Mobile) */}
                  {navigator.share && (
                    <IconButton
                      onClick={() => {
                        const shareText = `I just planted a tree with GreenVantage! Together, we've planted ${treeCount} trees! Join us in making a difference! 🌳`;
                        navigator.share({
                          title: 'I Just Planted a Tree! 🌱',
                          text: shareText,
                          url: window.location.href
                        }).catch(console.error);
                      }}
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: '#fff',
                        '&:hover': {
                          bgcolor: theme.palette.primary.dark,
                          transform: 'translateY(-2px)'
                        },
                        transition: 'all 0.3s ease'
                      }}
                    >
                      <ShareIcon />
                    </IconButton>
                  )}
                </Stack>
              </Box>
            </Box>
          </motion.div>
        );

      default:
        return null;
    }
  };

  return (
    <Box>
      <Container maxWidth="md">
        <Box
          sx={{
            minHeight: '100vh',
            py: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* Logos */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: { xs: 2, sm: 3 },
            mb: { xs: 4, sm: 6 }
          }}>
            <img
              src={treeNationLogo}
              alt="Tree Nation Logo"
              style={{ 
                width: 'auto',
                height: 'auto',
                maxWidth: '140px',
                '@media (max-width: 600px)': {
                  maxWidth: '100px'
                }
              }}
            />
            <Typography 
              variant="h4" 
              sx={{ 
                color: theme.palette.success.main,
                fontSize: { xs: '1.5rem', sm: '2rem' }
              }}
            >
              ×
            </Typography>
            <Box
              component="img"
              src={tgvBrandmark}
              alt="The GreenVantage"
              sx={{
                width: { xs: '100px', sm: '120px' },
                height: 'auto',
                objectFit: 'contain',
                ml: { xs: 1, sm: 2 }
              }}
            />
          </Box>

          {/* Form */}
          <Paper
            elevation={0}
            sx={{
              p: { xs: 2, sm: 3, md: 6 },
              width: '100%',
              maxWidth: 600,
              background: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(10px)',
              borderRadius: { xs: '12px', sm: '16px' },
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Stepper
              activeStep={activeStep}
              sx={{ 
                mb: { xs: 3, sm: 4, md: 6 },
                '& .MuiStepLabel-label': {
                  fontSize: { xs: '0.8rem', sm: '0.875rem' }
                }
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {renderStepContent(activeStep)}
          </Paper>

          {/* Live Notification */}
          <Snackbar
            open={notification.open}
            autoHideDuration={6000}
            onClose={() => setNotification({ ...notification, open: false })}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              onClose={() => setNotification({ ...notification, open: false })}
              severity="success"
              sx={{ width: '100%' }}
              elevation={6}
            >
              {notification.message}
            </Alert>
          </Snackbar>
        </Box>
      </Container>
    </Box>
  );
};

export default TreePlanting;
