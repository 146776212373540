import React, { useState, useEffect, useCallback, forwardRef, useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  CircularProgress,
  Alert,
  Divider,
  FormControlLabel,
  Switch,
  Tabs,
  Tab,
  IconButton,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Close as CloseIcon } from '@mui/icons-material';
import { createPost, updatePost, getPostBySlug, uploadImage } from '../services/blogService';
import { useAuth } from '../contexts/AuthContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { supabase } from '../lib/supabaseClient';

const TABLE_NAME = 'blog_posts';

// Create a custom Quill component with forwardRef to avoid findDOMNode warnings
const QuillEditor = forwardRef(({ value, onChange, modules, formats }, ref) => {
  // Create a local ref that we'll pass to ReactQuill
  const editorRef = useRef(null);
  
  // Sync our external ref with the internal one
  useEffect(() => {
    if (ref) {
      // If ref is a function ref
      if (typeof ref === 'function') {
        ref(editorRef.current);
      } 
      // If ref is an object ref
      else if (ref) {
        ref.current = editorRef.current;
      }
    }
  }, [ref]);
  
  return (
    <Box 
      className="quill-editor-container" 
      sx={{
        '& .ql-editor': {
          minHeight: '300px',
        },
        '& .ql-container': {
          fontSize: '16px',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
        },
        '& .ql-toolbar': {
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        }
      }}
    >
      <ReactQuill
        ref={editorRef}
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        style={{ height: '400px', marginBottom: '50px' }}
      />
    </Box>
  );
});

const PostEditor = () => {
  const { id } = useParams();
  const isEditing = Boolean(id);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  const [post, setPost] = useState({
    title: '',
    summary: '',
    content: '',
    published: true,
  });
  
  const [featuredImage, setFeaturedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  // Create a ref to store the subscription
  const subscriptionRef = useRef(null);

  // Quill editor modules and formats
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    }
  };
  
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  // Create a ref for the Quill editor
  const quillRef = React.useRef(null);

  // Helper function to update post state - moved before fetchPost
  const updatePostState = useCallback((postData) => {
    setPost({
      title: postData.title || '',
      summary: postData.summary || '',
      content: postData.content || '',
      published: postData.published !== false,
    });
    
    if (postData.featured_image) {
      setImagePreview(postData.featured_image);
    }
  }, []);

  // Handle image upload in Quill - memoize with useCallback
  const quillImageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    
    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        try {
          setLoading(true);
          const imageUrl = await uploadImage(file);
          
          // Get the Quill editor instance using the ref
          if (quillRef.current) {
            const editor = quillRef.current.getEditor();
            const range = editor.getSelection();
            
            // Insert the image at cursor position
            editor.insertEmbed(range.index, 'image', imageUrl);
          }
          
          setLoading(false);
        } catch (error) {
          console.error('Error uploading image:', error);
          setError('Failed to upload image. Please try again.');
          setLoading(false);
        }
      }
    };
  }, [setLoading, setError]);

  // Move the fetchPost function before the useEffect that uses it
  const fetchPost = useCallback(async () => {
    setLoading(true);
    try {
      // Check if id is a valid UUID
      const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      const isUuid = UUID_REGEX.test(id);
      const isNumericId = !isNaN(id) && !isUuid;
      
      // Initial fetch
      let postData;
      if (isNumericId || isUuid) {
        // Fetch by ID directly from Supabase (works for both numeric IDs and UUIDs)
        const { data, error } = await supabase
          .from(TABLE_NAME)
          .select('*')
          .eq('id', id)
          .single();
          
        if (error) {
          console.error('Supabase error fetching post by ID:', error);
          if (error.code === 'PGRST116') {
            setError(`Post with ID ${id} not found.`);
            setLoading(false);
            return;
          }
          throw error;
        }
        postData = data;
      } else {
        // Fetch by slug
        try {
          postData = await getPostBySlug(id);
          if (!postData) {
            setError(`Post with slug "${id}" not found.`);
            setLoading(false);
            return;
          }
        } catch (slugError) {
          console.error('Error fetching post by slug:', slugError);
          throw slugError;
        }
      }
      
      updatePostState(postData);
      
      // Set up realtime subscription for this post
      if (postData.id) {
        try {
          // Clean up any existing subscription
          if (subscriptionRef.current) {
            subscriptionRef.current.unsubscribe();
          }
          
          // Subscribe to changes for this specific post
          const channel = supabase
            .channel(`post-${postData.id}`)
            .on('postgres_changes', 
              { 
                event: '*', 
                schema: 'public', 
                table: TABLE_NAME,
                filter: `id=eq.${postData.id}`
              }, 
              (payload) => {
                console.log('Realtime update received:', payload);
                if (payload.new) {
                  updatePostState(payload.new);
                }
              }
            )
            .subscribe();
            
          subscriptionRef.current = channel;
        } catch (subscriptionError) {
          console.error('Error setting up realtime subscription:', subscriptionError);
          // Continue even if subscription fails - it's not critical
        }
      }
      
      setLoading(false);
    } catch (err) {
      console.error('Error fetching post:', err);
      setError(`Failed to load post: ${err.message || 'Unknown error'}`);
      setLoading(false);
    }
  }, [id, updatePostState]);
  
  useEffect(() => {
    // Redirect if not logged in
    if (!currentUser) {
      navigate('/login');
      return;
    }

    // If editing, fetch the post
    if (isEditing && id) {
      fetchPost();
    }
    
    // Add image handler to Quill toolbar
    if (typeof document !== 'undefined') {
      const imageButton = document.querySelector('.ql-image');
      if (imageButton) {
        imageButton.addEventListener('click', (e) => {
          e.preventDefault();
          quillImageHandler();
        });
      }
    }
    
    // Cleanup event listener and subscription
    return () => {
      if (typeof document !== 'undefined') {
        const imageButton = document.querySelector('.ql-image');
        if (imageButton) {
          imageButton.removeEventListener('click', quillImageHandler);
        }
      }
      
      // Clean up subscription
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, [currentUser, navigate, isEditing, id, fetchPost, quillImageHandler]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost(prev => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (content) => {
    setPost(prev => ({ ...prev, content }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setPost(prev => ({ ...prev, [name]: checked }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFeaturedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (!post.title.trim()) {
      setError('Title is required.');
      return;
    }
    
    if (!post.summary.trim()) {
      setError('Summary is required.');
      return;
    }
    
    if (!post.content.trim()) {
      setError('Content is required.');
      return;
    }
    
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      if (isEditing) {
        await updatePost(id, post, featuredImage);
        setSuccess('Post updated successfully!');
      } else {
        await createPost(post, featuredImage);
        setSuccess('Post created successfully!');
        
        // Reset form after successful creation
        if (!isEditing) {
          setPost({
            title: '',
            summary: '',
            content: '',
            published: true,
          });
          setFeaturedImage(null);
          setImagePreview('');
        }
      }
      
      // Navigate back to dashboard after a delay
      setTimeout(() => {
        navigate('/admin/dashboard');
      }, 2000);
    } catch (err) {
      console.error('Error saving post:', err);
      setError('Failed to save post. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {isEditing ? 'Edit Blog Post' : 'Create New Blog Post'}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => navigate('/admin/dashboard')}
          sx={{ mt: 1 }}
        >
          Back to Dashboard
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 4 }}>
          {success}
        </Alert>
      )}

      {loading && isEditing ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ ml: 2 }}>
            Loading post...
          </Typography>
        </Box>
      ) : (
        <Paper sx={{ p: 3 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Title"
                  name="title"
                  value={post.title}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  variant="outlined"
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Summary"
                  name="summary"
                  value={post.summary}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  variant="outlined"
                  multiline
                  rows={2}
                  disabled={loading}
                  helperText="A brief description of the post (shown in blog list)"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Featured Image
                </Typography>
                <input
                  accept="image/*"
                  id="featured-image-upload"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  disabled={loading}
                />
                <label htmlFor="featured-image-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    disabled={loading}
                  >
                    Upload Image
                  </Button>
                </label>
                {imagePreview && (
                  <Box sx={{ mt: 2, position: 'relative', width: 'fit-content' }}>
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '200px',
                        borderRadius: '4px',
                      }}
                    />
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        '&:hover': {
                          bgcolor: 'rgba(0, 0, 0, 0.7)',
                        },
                      }}
                      onClick={() => {
                        setFeaturedImage(null);
                        setImagePreview('');
                      }}
                      disabled={loading}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
                  <Tab label="Write" />
                  <Tab label="Preview" />
                </Tabs>

                {tabValue === 0 ? (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Content
                    </Typography>
                    <QuillEditor
                      ref={quillRef}
                      value={post.content}
                      onChange={handleContentChange}
                      modules={modules}
                      formats={formats}
                    />
                    <Typography variant="caption" color="text.secondary">
                      Use the toolbar above to format your content. You can add headings, bold/italic text, lists, links, and images.
                    </Typography>
                  </Box>
                ) : (
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      minHeight: '400px',
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 1,
                      '& img': {
                        maxWidth: '100%',
                        height: 'auto',
                      },
                    }}
                  >
                    {post.content ? (
                      <div dangerouslySetInnerHTML={{ __html: post.content }} />
                    ) : (
                      <Typography color="text.secondary" sx={{ fontStyle: 'italic' }}>
                        Preview will appear here
                      </Typography>
                    )}
                  </Paper>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={post.published}
                      onChange={handleSwitchChange}
                      name="published"
                      color="primary"
                      disabled={loading}
                    />
                  }
                  label="Publish immediately"
                />
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/admin/dashboard')}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  {loading ? 'Saving...' : isEditing ? 'Update Post' : 'Create Post'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    </Container>
  );
};

export default PostEditor; 