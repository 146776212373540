import React, { useEffect, useRef } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  useTheme,
  Paper,
  Fab,
  alpha
} from '@mui/material'; 
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import BoltIcon from '@mui/icons-material/Bolt';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SavingsIcon from '@mui/icons-material/Savings';
import { Helmet } from 'react-helmet-async';
import heroVideo from '../assets/HeroVideo.mp4';
import Typewriter from 'typewriter-effect';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useScroll, useTransform } from 'framer-motion';
import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import CampaignOutlined from '@mui/icons-material/CampaignOutlined';
import EnergySavingsLeafOutlined from '@mui/icons-material/EnergySavingsLeafOutlined';
import SEO from '../components/SEO';

// Constants for global ad industry statistics with sources
const GLOBAL_AD_INDUSTRY_STATS = {
  YEARLY_CARBON_EMISSIONS: {
    value: 2.1,
    label: 'CO₂ Emissions',
    sublabel: 'Mt/year',
    icon: <CloudQueueIcon />,
    source: 'IAB Europe'
  },
  ENERGY_CONSUMPTION: {
    value: 98,
    label: 'Energy Usage',
    sublabel: 'TWh/year',
    icon: <BoltIcon />,
    source: 'WFA'
  },
  WATER_USAGE: {
    value: 2.3,
    label: 'Water Usage',
    sublabel: 'Bn L/year',
    icon: <WaterDropIcon />,
    source: 'IAB Tech Lab'
  },
  COST_SAVINGS: {
    value: 47,
    label: 'Potential Savings',
    sublabel: 'Bn USD/year',
    icon: <SavingsIcon />,
    source: 'WFA'
  }
};

const HeroSection = ({ scrollY, opacity }) => {
  const theme = useTheme();
  const videoRef = useRef(null);
  const y = useTransform(scrollY, [0, 500], [0, 150]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1;
      videoRef.current.play().catch(error => {
        console.log("Video autoplay failed:", error);
      });
    }
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '50vh',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <motion.div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          y,
        }}
      >
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          playsInline
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 0,
            transform: 'scale(1.1)',
          }}
          preload="auto"
        >
          <source src={heroVideo} type="video/mp4" />
        </video>
      </motion.div>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 100%)',
          zIndex: 1,
        }}
      />
      
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
        <motion.div
          style={{ opacity }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Box sx={{ 
            textAlign: 'center',
            '& .Typewriter': { 
              display: 'inline-block',
              '& .Typewriter__wrapper': {
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                fontWeight: 700,
                background: `linear-gradient(135deg, #fff 0%, ${alpha('#fff', 0.8)} 100%)`,
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textShadow: 'none',
              },
              '& .Typewriter__cursor': {
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                fontWeight: 700,
                color: theme.palette.primary.main,
                textShadow: '0 0 8px rgba(255,255,255,0.4)',
              }
            }
          }}>
            <Typewriter
              options={{
                strings: [
                  'Measured Carbon Impact',
                  'Energy-Efficient Campaigns',
                  'Verified Emissions Data',
                  'Optimized Ad Delivery',
                  'Data-Driven Solutions'
                ],
                autoStart: true,
                loop: true,
                delay: 50,
                deleteSpeed: 30,
                pauseFor: 2000,
              }}
            />
          </Box>

          <Typography
            variant="h4"
            align="center"
            sx={{
              background: `linear-gradient(135deg, #fff 0%, ${alpha('#fff', 0.8)} 100%)`,
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 400,
              fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem' },
              letterSpacing: 0.5,
              lineHeight: 1.6,
              mb: 4,
              mt: 2,
              maxWidth: '800px',
              mx: 'auto',
            }}
          >
            Measure, reduce, and offset your advertising carbon footprint
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            <Button
              component={Link}
              to="/solutions"
              variant="contained"
              size="large"
              sx={{
                backgroundColor: alpha(theme.palette.primary.main, 0.9),
                backdropFilter: 'blur(4px)',
                color: '#fff',
                px: 4,
                py: 1.5,
                fontSize: '1.1rem',
                fontWeight: 600,
                borderRadius: 2,
                textTransform: 'none',
                boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.3)}`,
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                  transform: 'translateY(-2px)',
                  boxShadow: `0 12px 28px ${alpha(theme.palette.primary.main, 0.4)}`,
                },
              }}
            >
              Explore Solutions
            </Button>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

const PlatformFeatures = () => {
  const theme = useTheme();
  
  const features = [
    {
      title: 'Education',
      description: 'Learn about advertising\'s environmental impact through our comprehensive training programs and certification courses.',
      icon: <SchoolOutlined sx={{ fontSize: '2.5rem' }} />,
      link: '/solutions#education',
      color: theme.palette.primary.main
    },
    {
      title: 'Campaign Planning',
      description: 'Plan and execute sustainable advertising campaigns with our specialized tools and methodologies.',
      icon: <CampaignOutlined sx={{ fontSize: '2.5rem' }} />,
      link: '/solutions#planning',
      color: theme.palette.secondary.main
    },
    {
      title: 'Carbon Offsetting',
      description: 'Offset your campaign\'s carbon footprint through verified projects and real-time tracking.',
      icon: <EnergySavingsLeafOutlined sx={{ fontSize: '2.5rem' }} />,
      link: '/solutions#offsetting',
      color: theme.palette.success.main
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <Typography
          variant="h2"
          align="center"
          sx={{
            fontWeight: 700,
            fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' },
            mb: 2,
            background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          Why Choose Our Platform
        </Typography>
        
        <Typography
          variant="h5"
          align="center"
          sx={{
            color: theme.palette.text.secondary,
            mb: 8,
            maxWidth: '800px',
            mx: 'auto',
            fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
            fontWeight: 400,
            letterSpacing: 0.5,
            lineHeight: 1.6
          }}
        >
          Comprehensive solutions for sustainable advertising
        </Typography>
      </motion.div>

      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Paper
                component={Link}
                to={feature.link}
                elevation={0}
                sx={{
                  p: 4,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  borderRadius: 4,
                  background: 'rgba(255, 255, 255, 0.8)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid',
                  borderColor: 'divider',
                  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                  textDecoration: 'none',
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: theme.shadows[8],
                    borderColor: feature.color,
                    '& .feature-icon': {
                      transform: 'scale(1.1) rotate(5deg)',
                      color: feature.color,
                    },
                    '&::after': {
                      transform: 'scale(1.5)',
                      opacity: 0,
                    }
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '100px',
                    height: '100px',
                    backgroundColor: alpha(feature.color, 0.1),
                    borderRadius: '50%',
                    transform: 'translate(-50%, -50%) scale(0)',
                    transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
                    opacity: 0,
                    zIndex: 0,
                  }
                }}
              >
                <Box
                  className="feature-icon"
                  sx={{
                    mb: 2,
                    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                    color: theme.palette.text.primary,
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '64px',
                    height: '64px',
                    borderRadius: '50%',
                    backgroundColor: alpha(feature.color, 0.1),
                  }}
                >
                  {feature.icon}
                </Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    mb: 2,
                    color: theme.palette.text.primary,
                    position: 'relative',
                    zIndex: 1,
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.text.secondary,
                    lineHeight: 1.6,
                    position: 'relative',
                    zIndex: 1,
                  }}
                >
                  {feature.description}
                </Typography>
              </Paper>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const StatisticsSection = () => {
  const theme = useTheme();

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
      sx={{
        position: 'relative',
        py: { xs: 6, md: 8 },
        background: `linear-gradient(180deg, ${alpha(theme.palette.background.default, 0.95)} 0%, ${theme.palette.background.default} 100%)`,
        borderRadius: { xs: '24px 24px 0 0', md: '32px 32px 0 0' },
        mt: -3,
        zIndex: 2,
        boxShadow: '0 -10px 40px rgba(0,0,0,0.1)'
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <Box 
            sx={{ 
              textAlign: 'center', 
              mb: { xs: 4, md: 6 },
              '& > *': { 
                mb: 1,
                '&:last-child': {
                  mb: 0
                }
              }
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' },
                color: theme.palette.text.primary,
                mb: 2,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Industry Statistics
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                maxWidth: '600px',
                mx: 'auto',
                fontWeight: 400,
                fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                color: theme.palette.text.secondary,
                letterSpacing: 0.5,
                lineHeight: 1.6
              }}
            >
              Data from IAB Europe, IAB Tech Lab, and WFA (2023)
            </Typography>
          </Box>
        </motion.div>

        <Grid container spacing={{ xs: 3, md: 4 }}>
          {Object.values(GLOBAL_AD_INDUSTRY_STATS).map((stat, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    height: '100%',
                    p: { xs: 3, md: 4 },
                    textAlign: 'center',
                    borderRadius: 2,
                    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.default, 0.8)} 100%)`,
                    backdropFilter: 'blur(8px)',
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.15)}`
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 2,
                      color: theme.palette.primary.main,
                      '& svg': {
                        fontSize: { xs: '2rem', md: '2.5rem' }
                      }
                    }}
                  >
                    {stat.icon}
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: '1.5rem', md: '2rem' },
                      color: theme.palette.text.primary,
                      mb: 1,
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'center',
                      gap: 0.5
                    }}
                  >
                    <CountUp
                      end={stat.value}
                      decimals={stat.value % 1 !== 0 ? 1 : 0}
                      duration={2}
                    />
                    <Typography
                      component="span"
                      sx={{
                        fontSize: { xs: '0.875rem', md: '1rem' },
                        color: theme.palette.text.secondary,
                        fontWeight: 500
                      }}
                    >
                      {stat.sublabel}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: { xs: '0.875rem', md: '1rem' },
                      fontWeight: 500
                    }}
                  >
                    {stat.label}
                  </Typography>
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const Landing = () => {
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 300], [1, 0]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO page="home" />
      <ParallaxProvider>
        <Box sx={{ bgcolor: 'background.default', overflow: 'hidden' }}>
          <Helmet>
            <title>Green Ad Tech - Sustainable Digital Advertising Solutions</title>
            <meta name="description" content="Transform your digital advertising with sustainable solutions. Calculate and reduce your campaign's carbon footprint." />
            <meta property="og:title" content="Green Ad Tech - Sustainable Digital Advertising Solutions" />
            <meta property="og:description" content="Transform your digital advertising with sustainable solutions. Calculate and reduce your campaign's carbon footprint." />
            <meta name="twitter:title" content="Green Ad Tech - Sustainable Digital Advertising Solutions" />
            <meta name="twitter:description" content="Transform your digital advertising with sustainable solutions. Calculate and reduce your campaign's carbon footprint." />
          </Helmet>

          <HeroSection scrollY={scrollY} opacity={opacity} />
          
          {/* Impact Statistics Section */}
          <StatisticsSection />
          
          <PlatformFeatures />
          
          {/* Scroll to Top Button */}
          <Fab
            color="primary"
            size="medium"
            aria-label="scroll to top"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            sx={{
              position: 'fixed',
              bottom: 24,
              right: 24,
              display: 'flex',
              zIndex: 1000
            }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </ParallaxProvider>
    </>
  );
};

export default Landing;