import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  CircularProgress,
  useTheme,
  Pagination,
} from '@mui/material';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { getPosts } from '../services/blogService';
import SEO from '../components/SEO';

const Blog = () => {
  const theme = useTheme();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Use useCallback to memoize the fetchPosts function
  const fetchPosts = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getPosts(page);
      setPosts(result.posts);
      setTotalPages(result.totalPages);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching blog posts:', err);
      setError('Failed to load blog posts. Please try again later.');
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    // Fetch blog posts when component mounts or page/tag changes
    fetchPosts();
  }, [fetchPosts]);

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <SEO page="blog" />
      <Box>
        {/* Hero Section */}
        <Box
          sx={{
            background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
            color: 'white',
            py: 8,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Container maxWidth="lg">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <Typography
                variant="h2"
                textAlign="center"
                sx={{ fontWeight: 700, mb: 2 }}
              >
                Our Blog
              </Typography>
              <Typography
                variant="h5"
                textAlign="center"
                sx={{ opacity: 0.9, maxWidth: 700, mx: 'auto' }}
              >
                Insights, news, and perspectives on sustainable digital solutions
              </Typography>
            </motion.div>
          </Container>
        </Box>

        {/* Blog Posts Section */}
        <Container maxWidth="lg" sx={{ py: 8 }}>
          {loading && page === 1 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Box sx={{ textAlign: 'center', py: 8 }}>
              <Typography color="error" variant="h6" gutterBottom>
                {error}
              </Typography>
              <Button 
                variant="contained" 
                onClick={() => fetchPosts()}
                sx={{ mt: 2 }}
              >
                Try Again
              </Button>
            </Box>
          ) : posts.length === 0 ? (
            <Box sx={{ textAlign: 'center', py: 8 }}>
              <Typography variant="h6" gutterBottom>
                No blog posts found.
              </Typography>
            </Box>
          ) : (
            <>
              <Grid container spacing={4}>
                {posts.map((post, index) => (
                  <Grid item xs={12} md={6} lg={4} key={post.id}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.4, delay: index * 0.1 }}
                    >
                      <Card 
                        sx={{ 
                          height: '100%', 
                          display: 'flex', 
                          flexDirection: 'column',
                          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-8px)',
                            boxShadow: '0 12px 20px rgba(0, 0, 0, 0.1)',
                          }
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="200"
                          image={post.featured_image || 'https://via.placeholder.com/800x400'}
                          alt={post.title}
                        />
                        <CardContent sx={{ flexGrow: 1 }}>
                          <Typography 
                            variant="caption" 
                            color="text.secondary"
                            sx={{ 
                              mb: 1, 
                              fontStyle: 'italic',
                              backgroundColor: theme.palette.background.paper,
                              px: 1.5,
                              py: 0.5,
                              borderRadius: 1,
                              display: 'inline-block'
                            }}
                          >
                            {formatDate(post.created_at)}
                          </Typography>
                          <Typography 
                            variant="h5" 
                            component="h2"
                            gutterBottom
                            sx={{ 
                              fontWeight: 600,
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 2,
                              mt: 1
                            }}
                          >
                            {post.title}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            paragraph
                            sx={{ 
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 3,
                              mb: 2
                            }}
                          >
                            {post.summary}
                          </Typography>
                          <Button 
                            component={RouterLink} 
                            to={`/blog/${post.slug}`}
                            variant="outlined" 
                            color="primary"
                            size="small"
                          >
                            Read More
                          </Button>
                        </CardContent>
                      </Card>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>

              {/* Pagination */}
              {totalPages > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
                  <Pagination 
                    count={totalPages} 
                    page={page} 
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    disabled={loading}
                  />
                </Box>
              )}
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Blog; 