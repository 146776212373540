import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Typography, Paper, Button } from '@mui/material';
import { motion } from 'framer-motion';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import { format } from 'date-fns';
import { decodeCertificateData } from '../utils/certificateUtils';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from '../assets/TGVlogo.png';

const TreeCertificate = () => {
  const { certificateId } = useParams();
  const plantingDate = new Date('2024-12-18');

  useEffect(() => {
    const header = document.querySelector('header');
    const footer = document.querySelector('footer');
    if (header) header.style.display = 'none';
    if (footer) footer.style.display = 'none';

    return () => {
      if (header) header.style.display = '';
      if (footer) footer.style.display = '';
    };
  }, []);

  const certificateData = decodeCertificateData(certificateId);

  const handleDownloadPDF = async () => {
    const certificate = document.getElementById('certificate');
    const canvas = await html2canvas(certificate, {
      scale: 3,
      backgroundColor: '#ffffff',
      width: certificate.offsetWidth,
      height: certificate.offsetHeight,
      useCORS: true,
      logging: true,
      onclone: (doc) => {
        doc.fonts.ready.then(() => {
          console.log('Fonts loaded');
        });
      }
    });
    
    const imgData = canvas.toDataURL('image/jpeg', 1.0);
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4'
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfImgWidth = pdfWidth - 20;
    const imgRatio = canvas.height / canvas.width;
    const pdfImgHeight = pdfImgWidth * imgRatio;

    pdf.addImage(imgData, 'JPEG', 10, 10, pdfImgWidth, pdfImgHeight);
    pdf.save(`Tree-Certificate-${certificateData?.name.replace(/\s+/g, '-')}.pdf`);
  };

  if (!certificateData) return null;

  return (
    <Box sx={{ 
      minHeight: '100vh', 
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: '#f5f5f5',
      py: 4
    }}>
      <Container maxWidth="md">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Paper
            id="certificate"
            elevation={3}
            sx={{
              p: 5,
              background: '#ffffff',
              position: 'relative',
              overflow: 'hidden',
              boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
              borderRadius: 0,
              border: '2px solid #2E7D32',
              width: '100%',
              maxWidth: '800px',
              height: 'auto',
              minHeight: '600px',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: '20px',
                left: '20px',
                right: '20px',
                bottom: '20px',
                border: '1px solid rgba(46, 125, 50, 0.3)',
                pointerEvents: 'none'
              }
            }}
          >
            {/* Decorative Corner */}
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '80mm',
              height: '80mm',
              background: 'radial-gradient(circle at 0 0, rgba(46, 125, 50, 0.05) 0%, transparent 70%)'
            }} />

            {/* Logo */}
            <Box sx={{
              width: '130px',
              height: 'auto',
              mt: 2,
              mb: 4,
              position: 'relative',
              img: { 
                width: '100%',
                height: 'auto',
                objectFit: 'contain'
              }
            }}>
              <img src={logo} alt="The Green Vantage" />
            </Box>

            {/* Certificate Title */}
            <Typography 
              variant="h2" 
              align="center"
              sx={{ 
                fontFamily: 'Cormorant Garamond',
                color: '#2E7D32',
                fontWeight: 600,
                letterSpacing: '0.1em',
                textTransform: 'uppercase',
                mb: 3,
                fontSize: '2rem',
                lineHeight: 1.2
              }}
            >
              Certificate of Tree Planting
            </Typography>

            <Typography 
              variant="h5" 
              align="center"
              sx={{ 
                fontFamily: 'Cormorant Garamond',
                fontStyle: 'italic',
                color: '#666',
                mb: 2,
                fontWeight: 500,
                fontSize: '1.2rem'
              }}
            >
              This certifies that
            </Typography>

            {/* Name Container */}
            <Box sx={{
              width: '90%',
              position: 'relative',
              mb: 3,
              '&::before, &::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                height: '1px',
                background: 'linear-gradient(to right, transparent, #2E7D32, transparent)'
              },
              '&::before': { top: 0 },
              '&::after': { bottom: 0 }
            }}>
              <Typography 
                variant="h2" 
                align="center"
                sx={{ 
                  fontFamily: 'Cormorant Garamond',
                  fontWeight: 700,
                  color: '#000',
                  py: 2,
                  fontSize: '2.5rem',
                  wordBreak: 'break-word'
                }}
              >
                {certificateData.name}
              </Typography>
            </Box>

            <Typography 
              variant="body1" 
              align="center"
              sx={{ 
                fontFamily: 'Montserrat',
                width: '90%',
                lineHeight: 1.6,
                color: '#333',
                fontWeight: 400,
                fontSize: '1rem',
                mb: 3
              }}
            >
              has planted a tree in Madagascar's mangrove forest as part of our commitment
              to environmental conservation and sustainable development.
            </Typography>

            {/* Footer Info */}
            <Box sx={{
              width: '100%',
              textAlign: 'center',
              mt: 'auto',
              mb: 3
            }}>
              <Typography 
                sx={{ 
                  fontFamily: 'Montserrat',
                  color: '#666',
                  fontSize: '0.9rem',
                  mb: 1
                }}
              >
                Planted on {format(plantingDate, 'MMMM do, yyyy')}
              </Typography>
              <Typography 
                sx={{ 
                  fontFamily: 'Montserrat',
                  color: '#666',
                  fontSize: '0.8rem',
                  opacity: 0.8
                }}
              >
                Certificate ID: {certificateData.id}
              </Typography>
            </Box>
          </Paper>

          {/* Action Buttons */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            gap: 2, 
            mt: 4 
          }}>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadPDF}
              sx={{ 
                bgcolor: '#2E7D32',
                fontFamily: 'Montserrat',
                textTransform: 'none',
                px: 4,
                py: 1.5,
                '&:hover': {
                  bgcolor: '#1B5E20'
                }
              }}
            >
              Download PDF
            </Button>
            <Button
              variant="outlined"
              startIcon={<ShareIcon />}
              sx={{ 
                color: '#2E7D32',
                borderColor: '#2E7D32',
                fontFamily: 'Montserrat',
                textTransform: 'none',
                px: 4,
                py: 1.5,
                '&:hover': {
                  borderColor: '#1B5E20',
                  bgcolor: 'rgba(46, 125, 50, 0.1)'
                }
              }}
            >
              Share
            </Button>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

export default TreeCertificate;
