import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  Typography,
  Breadcrumbs,
  Link,
  CircularProgress,
  Button,
  useTheme,
  Paper,
  IconButton,
  Card,
  CardContent,
  Stack,
  Snackbar,
  Alert,
  Chip,
  Avatar,
} from '@mui/material';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getPostBySlug } from '../services/blogService';
import SEO from '../components/SEO';
import { useAuth } from '../contexts/AuthContext';
// Import social media icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';

const BlogPost = () => {
  const theme = useTheme();
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showShareSnackbar, setShowShareSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  // Use useCallback to memoize the fetchPost function
  const fetchPost = useCallback(async () => {
    setLoading(true);
    try {
      const postData = await getPostBySlug(slug);
      
      if (!postData) {
        setError('Blog post not found.');
        setLoading(false);
        return;
      }
      
      setPost(postData);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching blog post:', err);
      setError('Failed to load blog post. Please try again later.');
      setLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    fetchPost();
    window.scrollTo(0, 0);
  }, [fetchPost]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const calculateReadingTime = (content) => {
    if (!content) return '1 min read';
    const text = content.replace(/<[^>]*>/g, '');
    const words = text.split(/\s+/).length;
    const minutes = Math.ceil(words / 200);
    return `${minutes} min read`;
  };

  const handleShare = async (platform) => {
    if (!post) return;
    
    const shareUrl = window.location.href;
    const shareTitle = post.title;
    const shareText = `Check out this article: ${post.title}`;
    
    try {
      switch (platform) {
        case 'facebook':
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank');
          setSnackbarMessage('Sharing on Facebook...');
          break;
        case 'twitter':
          window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`, '_blank');
          setSnackbarMessage('Sharing on Twitter...');
          break;
        case 'linkedin':
          window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}&summary=${encodeURIComponent(shareText)}`, '_blank');
          setSnackbarMessage('Sharing on LinkedIn...');
          break;
        case 'copy':
          await navigator.clipboard.writeText(shareUrl);
          setSnackbarMessage('Link copied to clipboard!');
          break;
        case 'native':
          if (navigator.share) {
            await navigator.share({
              title: shareTitle,
              text: shareText,
              url: shareUrl,
            });
            setSnackbarMessage('Opening share dialog...');
          }
          break;
        default:
          break;
      }
      setShowShareSnackbar(true);
    } catch (err) {
      console.error('Error sharing:', err);
      setSnackbarMessage('Failed to share. Please try again.');
      setShowShareSnackbar(true);
    }
  };

  const handleSnackbarClose = () => {
    setShowShareSnackbar(false);
  };

  return (
    <>
      {post && <SEO title={post.title} description={post.summary} image={post.featured_image} />}
      <Box sx={{ bgcolor: 'background.default' }}>
        {loading ? (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            minHeight: '60vh'
          }}>
            <CircularProgress size={40} />
          </Box>
        ) : error ? (
          <Container maxWidth="lg" sx={{ py: 12, textAlign: 'center' }}>
            <Typography color="error" variant="h6" gutterBottom>
              {error}
            </Typography>
            <Button 
              variant="contained" 
              component={RouterLink} 
              to="/blog"
              startIcon={<ArrowBackIcon />}
              sx={{ 
                mt: 2,
                borderRadius: 28,
                px: 3,
                py: 1,
                boxShadow: theme.shadows[2],
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: theme.shadows[4],
                }
              }}
            >
              Back to Blog
            </Button>
          </Container>
        ) : post ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {/* Hero Section */}
            <Box
              sx={{
                position: 'relative',
                height: { xs: '400px', md: '500px' },
                width: '100%',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.9) 100%)',
                  zIndex: 1,
                },
              }}
            >
              <Box
                component="img"
                src={post.featured_image || 'https://via.placeholder.com/1200x600'}
                alt={post.title}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'transform 0.6s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              />
              
              {/* Back to Blog Button (Mobile) */}
              <Box
                sx={{
                  position: 'absolute',
                  top: { xs: 80, sm: 16 },
                  left: 16,
                  zIndex: 2,
                  display: { xs: 'block', sm: 'none' },
                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '50%',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <IconButton
                  component={RouterLink}
                  to="/blog"
                  sx={{
                    color: 'text.primary',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Box>

              <Container
                maxWidth="lg"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 2,
                  color: 'white',
                  pb: { xs: 4, md: 6 },
                  px: { xs: 2, sm: 3, md: 4 },
                }}
              >
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                >
                  {/* Post Meta */}
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ 
                      mb: { xs: 2, md: 3 }, 
                      mt: { xs: 4, md: 0 },
                      flexWrap: 'wrap', 
                      gap: 1,
                      justifyContent: { xs: 'center', md: 'flex-start' }
                    }}
                  >
                    <Chip
                      icon={<CalendarTodayIcon sx={{ color: 'white !important' }} />}
                      label={formatDate(post.created_at)}
                      sx={{
                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                        color: 'white',
                        backdropFilter: 'blur(4px)',
                        '& .MuiChip-icon': {
                          color: 'white',
                        },
                      }}
                    />
                    <Chip
                      icon={<AccessTimeIcon sx={{ color: 'white !important' }} />}
                      label={calculateReadingTime(post.content)}
                      sx={{
                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                        color: 'white',
                        backdropFilter: 'blur(4px)',
                      }}
                    />
                    {post.author && (
                      <Chip
                        icon={<PersonIcon sx={{ color: 'white !important' }} />}
                        avatar={post.author.avatar ? <Avatar src={post.author.avatar} /> : null}
                        label={post.author.name || 'Anonymous'}
                        sx={{
                          bgcolor: 'rgba(255, 255, 255, 0.1)',
                          color: 'white',
                          backdropFilter: 'blur(4px)',
                        }}
                      />
                    )}
                  </Stack>

                  <Typography
                    variant="h1"
                    align="left"
                    sx={{
                      fontSize: { xs: '1.75rem', sm: '2.25rem', md: '3rem' },
                      fontWeight: 700,
                      textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                      mb: { xs: 2, md: 3 },
                      lineHeight: 1.2,
                      textAlign: { xs: 'center', md: 'left' },
                      maxWidth: '800px',
                    }}
                  >
                    {post.title}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                      opacity: 0.9,
                      maxWidth: '800px',
                      textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                      display: '-webkit-box',
                      WebkitLineClamp: { xs: 2, md: 3 },
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textAlign: { xs: 'center', md: 'left' },
                      mx: { xs: 'auto', md: 0 },
                    }}
                  >
                    {post.summary}
                  </Typography>
                </motion.div>
              </Container>
            </Box>

            <Container maxWidth="lg" sx={{ py: { xs: 4, md: 6 } }}>
              {/* Desktop Navigation */}
              <Box sx={{ display: { xs: 'none', sm: 'block' }, mb: 4 }}>
                <Breadcrumbs sx={{ mb: 2 }}>
                  <Link component={RouterLink} to="/" color="inherit" sx={{ 
                    '&:hover': { color: theme.palette.primary.main } 
                  }}>
                    Home
                  </Link>
                  <Link component={RouterLink} to="/blog" color="inherit" sx={{ 
                    '&:hover': { color: theme.palette.primary.main } 
                  }}>
                    Blog
                  </Link>
                  <Typography color="text.primary" noWrap sx={{ maxWidth: '300px' }}>
                    {post.title}
                  </Typography>
                </Breadcrumbs>
              </Box>

              {/* Content Container */}
              <Box sx={{ display: 'flex', gap: 4, flexDirection: { xs: 'column', lg: 'row' } }}>
                {/* Main Content */}
                <Box sx={{ flex: 1 }}>
                  <Paper 
                    elevation={0} 
                    sx={{ 
                      p: { xs: 2, sm: 3, md: 4 },
                      borderRadius: 4,
                      bgcolor: 'background.paper',
                      boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
                      '& img': {
                        maxWidth: '100%',
                        height: 'auto',
                        borderRadius: 2,
                        my: 3,
                        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                      },
                      '& h1, & h2, & h3, & h4, & h5, & h6': {
                        mt: 4,
                        mb: 2,
                        color: 'text.primary',
                        fontWeight: 600,
                        lineHeight: 1.4,
                      },
                      '& p': {
                        mb: 2,
                        lineHeight: 1.8,
                        fontSize: { xs: '1rem', md: '1.1rem' },
                        color: 'text.primary',
                      },
                      '& a': {
                        color: theme.palette.primary.main,
                        textDecoration: 'none',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          color: theme.palette.primary.dark,
                          textDecoration: 'underline',
                        },
                      },
                      '& blockquote': {
                        borderLeft: `4px solid ${theme.palette.primary.main}`,
                        pl: 3,
                        py: 1,
                        my: 3,
                        bgcolor: 'rgba(0, 0, 0, 0.02)',
                        borderRadius: '0 8px 8px 0',
                        fontStyle: 'italic',
                      },
                      '& code': {
                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                        p: 0.5,
                        borderRadius: 1,
                        fontFamily: 'monospace',
                      },
                      '& pre': {
                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                        p: 2,
                        borderRadius: 2,
                        overflow: 'auto',
                        '& code': {
                          bgcolor: 'transparent',
                          p: 0,
                        },
                      },
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                  </Paper>

                  {/* Share Buttons */}
                  <Box sx={{ mt: 6, display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
                    <Button
                      variant="outlined"
                      startIcon={<FacebookIcon />}
                      onClick={() => handleShare('facebook')}
                      sx={{
                        borderRadius: 2,
                        borderColor: '#1877F2',
                        color: '#1877F2',
                        '&:hover': {
                          borderColor: '#0E5FC0',
                          bgcolor: 'rgba(24, 119, 242, 0.1)',
                          transform: 'translateY(-2px)',
                        },
                      }}
                    >
                      Share
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<TwitterIcon />}
                      onClick={() => handleShare('twitter')}
                      sx={{
                        borderRadius: 2,
                        borderColor: '#1DA1F2',
                        color: '#1DA1F2',
                        '&:hover': {
                          borderColor: '#0D8ECF',
                          bgcolor: 'rgba(29, 161, 242, 0.1)',
                          transform: 'translateY(-2px)',
                        },
                      }}
                    >
                      Tweet
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<LinkedInIcon />}
                      onClick={() => handleShare('linkedin')}
                      sx={{
                        borderRadius: 2,
                        borderColor: '#0A66C2',
                        color: '#0A66C2',
                        '&:hover': {
                          borderColor: '#084E96',
                          bgcolor: 'rgba(10, 102, 194, 0.1)',
                          transform: 'translateY(-2px)',
                        },
                      }}
                    >
                      Post
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<ContentCopyIcon />}
                      onClick={() => handleShare('copy')}
                      sx={{
                        borderRadius: 2,
                        '&:hover': {
                          transform: 'translateY(-2px)',
                        },
                      }}
                    >
                      Copy Link
                    </Button>
                  </Box>
                </Box>

                {/* Sidebar */}
                <Box 
                  sx={{ 
                    width: { xs: '100%', lg: '300px' },
                    position: { xs: 'static', lg: 'sticky' },
                    top: 100,
                    alignSelf: 'flex-start',
                  }}
                >
                  {/* Admin Actions */}
                  {currentUser && currentUser.email && (
                    <Card sx={{ borderRadius: 4, overflow: 'hidden' }}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                          Admin Actions
                        </Typography>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => navigate(`/admin/edit-post/${post.id}`)}
                          sx={{
                            borderRadius: 2,
                            '&:hover': {
                              transform: 'translateY(-2px)',
                            },
                          }}
                        >
                          Edit Post
                        </Button>
                      </CardContent>
                    </Card>
                  )}
                </Box>
              </Box>

              {/* Back to Blog (Desktop) */}
              <Box sx={{ 
                mt: 6, 
                display: { xs: 'none', sm: 'flex' }, 
                justifyContent: 'center' 
              }}>
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to="/blog"
                  startIcon={<ArrowBackIcon />}
                  size="large"
                  sx={{
                    borderRadius: 28,
                    px: 4,
                    py: 1.5,
                    borderWidth: 2,
                    '&:hover': {
                      borderWidth: 2,
                      transform: 'translateY(-2px)',
                      boxShadow: theme.shadows[2],
                    },
                  }}
                >
                  Back to Blog
                </Button>
              </Box>
            </Container>
          </motion.div>
        ) : null}
      </Box>

      {/* Snackbar for notifications */}
      <Snackbar
        open={showShareSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity="success" 
          variant="filled"
          sx={{ 
            width: '100%',
            borderRadius: 2,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BlogPost; 