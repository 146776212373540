import { supabase } from '../lib/supabaseClient';

const POSTS_PER_PAGE = 6;
const TABLE_NAME = 'blog_posts';
const STORAGE_BUCKET = 'blog_images';

// Create a new blog post
export const createPost = async (postData, featuredImage) => {
  try {
    // Create a slug from the title
    const slug = createSlug(postData.title);

    // Check if slug already exists
    const slugExists = await checkSlugExists(slug);
    const finalSlug = slugExists ? `${slug}-${Date.now()}` : slug;

    const now = new Date().toISOString();

    // First, create the post without the image
    const { data: initialPost, error: initialError } = await supabase
      .from(TABLE_NAME)
      .insert([{
        ...postData,
        slug: finalSlug,
        created_at: now,
        updated_at: now,
        published: true
      }])
      .select();

    if (initialError) throw initialError;
    
    // If no image, we're done
    if (!featuredImage) {
      return initialPost[0];
    }

    // If we have an image, upload it asynchronously
    const fileExt = featuredImage.name.split('.').pop();
    const fileName = `${Date.now()}_${Math.random().toString(36).substring(2, 15)}.${fileExt}`;
    const filePath = `${fileName}`;

    // Upload to Supabase Storage
    const { error: uploadError } = await supabase
      .storage
      .from(STORAGE_BUCKET)
      .upload(filePath, featuredImage);

    if (uploadError) throw uploadError;

    // Get the public URL
    const { data: urlData } = supabase
      .storage
      .from(STORAGE_BUCKET)
      .getPublicUrl(filePath);

    const imageUrl = urlData.publicUrl;

    // Update the post with the image URL
    const { data: updatedPost, error: updateError } = await supabase
      .from(TABLE_NAME)
      .update({ featured_image: imageUrl })
      .eq('id', initialPost[0].id)
      .select();

    if (updateError) throw updateError;

    return updatedPost[0];
  } catch (error) {
    console.error('Error creating blog post:', error);
    throw error;
  }
};

// Update an existing blog post
export const updatePost = async (postId, postData, featuredImage) => {
  try {
    // Get the current post data
    const { data: currentPost, error: fetchError } = await supabase
      .from(TABLE_NAME)
      .select('*')
      .eq('id', postId)
      .single();

    if (fetchError) throw fetchError;
    if (!currentPost) throw new Error('Post not found');

    let imageUrl = currentPost.featured_image;
    let imageUploadPromise = Promise.resolve();

    // If a new image is provided, upload it and update the URL
    if (featuredImage) {
      // Start the image upload process asynchronously
      imageUploadPromise = (async () => {
        // Delete the old image if it exists
        if (currentPost.featured_image) {
          const oldImagePath = currentPost.featured_image.split('/').pop();
          try {
            const { error: deleteError } = await supabase
              .storage
              .from(STORAGE_BUCKET)
              .remove([oldImagePath]);
              
            if (deleteError) console.warn('Error deleting old image:', deleteError);
          } catch (error) {
            console.warn('Error deleting old image:', error);
          }
        }

        // Upload the new image
        const fileExt = featuredImage.name.split('.').pop();
        const fileName = `${Date.now()}_${Math.random().toString(36).substring(2, 15)}.${fileExt}`;
        const filePath = `${fileName}`;

        // Upload to Supabase Storage
        const { error: uploadError } = await supabase
          .storage
          .from(STORAGE_BUCKET)
          .upload(filePath, featuredImage);

        if (uploadError) throw uploadError;

        // Get the public URL
        const { data: urlData } = supabase
          .storage
          .from(STORAGE_BUCKET)
          .getPublicUrl(filePath);

        return urlData.publicUrl;
      })();
    }

    const now = new Date().toISOString();

    // First update the post content immediately (without waiting for image upload)
    // This allows realtime subscribers to get the text content updates right away
    const { data: initialUpdate, error: initialError } = await supabase
      .from(TABLE_NAME)
      .update({
        ...postData,
        updated_at: now
      })
      .eq('id', postId)
      .select();

    if (initialError) throw initialError;

    // If we're uploading an image, wait for that to complete and then update the image URL
    if (featuredImage) {
      imageUrl = await imageUploadPromise;
      
      // Update just the image URL
      const { data: imageUpdate, error: imageError } = await supabase
        .from(TABLE_NAME)
        .update({
          featured_image: imageUrl,
        })
        .eq('id', postId)
        .select();
        
      if (imageError) throw imageError;
      
      return imageUpdate[0];
    }

    return initialUpdate[0];
  } catch (error) {
    console.error('Error updating blog post:', error);
    throw error;
  }
};

// Delete a blog post
export const deletePost = async (postId) => {
  try {
    // Get the current post data
    const { data: currentPost, error: fetchError } = await supabase
      .from(TABLE_NAME)
      .select('*')
      .eq('id', postId)
      .single();

    if (fetchError) throw fetchError;
    if (!currentPost) throw new Error('Post not found');

    // Delete the featured image if it exists
    if (currentPost.featured_image) {
      const imagePath = currentPost.featured_image.split('/').pop();
      try {
        const { error: deleteError } = await supabase
          .storage
          .from(STORAGE_BUCKET)
          .remove([imagePath]);
          
        if (deleteError) console.warn('Error deleting image:', deleteError);
      } catch (error) {
        console.warn('Error deleting image:', error);
      }
    }

    // Delete the post from Supabase
    const { error } = await supabase
      .from(TABLE_NAME)
      .delete()
      .eq('id', postId);

    if (error) throw error;

    return { id: postId };
  } catch (error) {
    console.error('Error deleting blog post:', error);
    throw error;
  }
};

// Get all blog posts with pagination
export const getPosts = async (pageOrLastDoc = 1) => {
  try {
    let query = supabase
      .from(TABLE_NAME)
      .select('*', { count: 'exact' });
    
    // For admin dashboard, we want to show all posts
    // For public blog, we only want to show published posts
    if (typeof pageOrLastDoc === 'number') {
      query = query.eq('published', true);
    }
    
    // Order by creation date, newest first
    query = query.order('created_at', { ascending: false });

    // Apply pagination
    if (typeof pageOrLastDoc === 'number') {
      // Page number based pagination (for public blog)
      const page = pageOrLastDoc;
      const from = (page - 1) * POSTS_PER_PAGE;
      const to = from + POSTS_PER_PAGE - 1;
      query = query.range(from, to);
    }
    // No need for cursor-based pagination with Supabase, we just use page-based

    const { data, error, count } = await query;

    if (error) throw error;

    return {
      posts: data || [],
      totalCount: count || 0,
      totalPages: Math.ceil((count || 0) / POSTS_PER_PAGE),
      currentPage: typeof pageOrLastDoc === 'number' ? pageOrLastDoc : 1,
      lastVisible: null // Not needed with Supabase, but keeping for API compatibility
    };
  } catch (error) {
    console.error('Error getting blog posts:', error);
    throw error;
  }
};

// Get a single blog post by slug or ID
export const getPostBySlug = async (slugOrId) => {
  try {
    // Check if the parameter is a UUID
    const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const isUuid = UUID_REGEX.test(slugOrId);
    
    // Check if the parameter is a numeric ID
    const isNumericId = !isNaN(slugOrId) && !isUuid;
    
    // Build the query based on the type of identifier
    let query;
    
    if (isUuid) {
      // Direct query by UUID is most efficient
      query = supabase
        .from(TABLE_NAME)
        .select('*')
        .eq('id', slugOrId);
    } else if (isNumericId) {
      // Query by numeric ID
      query = supabase
        .from(TABLE_NAME)
        .select('*')
        .eq('id', slugOrId);
    } else {
      // Query by slug
      query = supabase
        .from(TABLE_NAME)
        .select('*')
        .eq('slug', slugOrId);
    }
    
    const { data, error } = await query.single();

    if (error) {
      if (error.code === 'PGRST116') {
        return null; // No post found with this slug/id
      }
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error getting blog post:', error);
    throw error;
  }
};

// Get all tags
export const getTags = async () => {
  try {
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .select('tags')
      .eq('published', true);

    if (error) throw error;
    
    // Extract all tags from all posts
    const tagsSet = new Set();
    data.forEach(post => {
      const tags = post.tags || [];
      tags.forEach(tag => tagsSet.add(tag));
    });

    return Array.from(tagsSet);
  } catch (error) {
    console.error('Error getting tags:', error);
    throw error;
  }
};

// Helper function to create a slug from a title
const createSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
    .trim();
};

// Helper function to check if a slug already exists
const checkSlugExists = async (slug) => {
  const { data, error } = await supabase
    .from(TABLE_NAME)
    .select('id')
    .eq('slug', slug)
    .single();

  if (error && error.code !== 'PGRST116') {
    throw error;
  }

  return !!data;
};

// Upload an image to Supabase Storage
export const uploadImage = async (file) => {
  try {
    const fileExt = file.name.split('.').pop();
    const fileName = `${Date.now()}_${Math.random().toString(36).substring(2, 15)}.${fileExt}`;
    const filePath = `${fileName}`;

    // Upload to Supabase Storage
    const { error } = await supabase
      .storage
      .from(STORAGE_BUCKET)
      .upload(filePath, file);

    if (error) throw error;

    // Get the public URL
    const { data: urlData } = supabase
      .storage
      .from(STORAGE_BUCKET)
      .getPublicUrl(filePath);

    return urlData.publicUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
}; 